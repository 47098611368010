import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer" 
import Loadable from "@loadable/component"
import { Container, Col, Row } from "react-bootstrap";
import { Link, navigate } from "gatsby"
import axios from "axios"

const OpenStreetMap = Loadable(() => import("../components/maps/openstreet/open-view-map"))

// the below async function wait till get data from strapi using graphql
const getPropurl = async (handler) => {
  let result = [];
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    "query": "query{ properties(where: { latitude_gte: 0, publish: true}) { id, slug, search_type, department, latitude, longitude, title, display_address } }",

  });
  console.log("🚀 ~ file: 404.js ~ line 17 ~ getPropurl ~ data", data)
  var config = {
    method: "post",
    url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
    data: data,
  }
  const res = await axios(config)
  // console.log('resulttt', res.data)
  // result[0] = JSON.stringify(res.data)
  handler(res.data)  
}

const OpenStreet = (props) => {
  const [render, setRender] = React.useState(false)
  const [propdata, setPropdata] = React.useState("") // false

  React.useEffect(() => { 
    
    if (!propdata && !render) {
      setRender(true);
      getPropurl(setPropdata) // call the function to fetch data from strapi
    }  
  }, [propdata])
 
  return (
    <React.Fragment> 
      {propdata.data && propdata.data?.properties && render &&
          <OpenStreetMap hits={propdata.data?.properties ? propdata.data?.properties : []} />  
      }
    
    </React.Fragment>
  )
}
export default OpenStreet;

